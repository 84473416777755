import React from "react";
import { IconButton } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { makeStyles } from "@mui/styles";
import scrollTo from "gatsby-plugin-smoothscroll";

const useStyles = makeStyles((_theme) => ({
  popup_wrapper: {
    position: "absolute",
    bottom: "55px",
    right: "20px",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: "100",
  },
}));

const ScrollUp = () => {
  const classes = useStyles();

  const scrollToTop = () => {
    scrollTo("#scroll-anchor");
  };

  return (
    <IconButton className={classes.popup_wrapper} onClick={scrollToTop}>
      <ArrowCircleUpIcon fontSize="large" />
    </IconButton>
  );
};

export default ScrollUp;
