import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';

import { doAuthenticatedGet } from '../../../actions/_methods';
import { previewNotificationActions } from '../../../store/preview_notification_slice';
import { API_TASKS } from '../../../const/api_paths';

export default ({notification, onClick}) => {
  const dispatch = useDispatch();

  const loadTask = () => {
    doAuthenticatedGet(`${API_TASKS}/${notification.notifiable_id}`, {}, {
      success: onSuccessLoadTask,
      general: onSuccessLoadTask,
      error:   (res) => alert("Please try again"),
    })
  }

  const onSuccessLoadTask = ({task}) => {
    dispatch(
      previewNotificationActions.setNotification({
        notification: notification,
        data: task
      })
    );
  };


  const handleNotificationClick = () => {
    if(notification.notifiable_type === 'Task') {
      dispatch(previewNotificationActions.setLoading(true));
      dispatch(previewNotificationActions.setOpen(true));
      loadTask()
    }

    onClick();
  }

  const cicleIcon = () => (
    <CircleIcon
      style={{color: '#1776d2', marginTop: '15px', marginRight: '10px'}}
      fontSize="small"
    />
  )

  return (
    <MenuItem onClick={handleNotificationClick} key={`notification_${Math.random()}`}>
      <ListItem alignItems="flex-start">
        {!notification.opened && cicleIcon()}
        <ListItemAvatar>
          <Avatar alt="" src={notification.image_url} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span dangerouslySetInnerHTML={{ __html: notification.message.split("\n")[0] }} />
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <span dangerouslySetInnerHTML={{ __html: notification.message.split("\n")[1]  }} />
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </MenuItem>
  );

};