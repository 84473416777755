import React from "react";
import { Link } from "@mui/material/";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import Tooltip from "@mui/material/Tooltip";
import CategoryIcon from "@mui/icons-material/Category";
import BusinessIcon from "@mui/icons-material/Business";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GradingIcon from '@mui/icons-material/Grading';
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountTree from "@mui/icons-material/AccountTree";
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import { IfAnyGranted, IfAuthorized } from "react-authorization";
import { usePermission } from "../../context/permission";

export default () => {
  const {
    atleastSuperAngel,
    atleastAngel,
    atleastAdmin,
    atleastManager,
    getMyRoles,
    hasReports,
  } = usePermission();

  return (
    <div>
      <Link href={`/app/my_tasks`} color="inherit">
        <Tooltip title="My Tasks for Today" placement="right" arrow>
          <ListItem>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="My Tasks for Today" />
          </ListItem>
        </Tooltip>
      </Link>
      <Link href={`/app/all`} color="inherit">
        <Tooltip title="Dashboard" placement="right" arrow>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Tooltip>
      </Link>

      <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
        <Link href={`/app/manage/users`} color="inherit">
          <Tooltip title="Users" placement="right" arrow>
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Tooltip>
        </Link>
        <Link href={`/app/manage/categories`} color="inherit">
          <Tooltip title="Categories" placement="right" arrow>
            <ListItem button>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItem>
          </Tooltip>
        </Link>
      </IfAnyGranted>
      <IfAnyGranted expected={atleastManager()} actual={getMyRoles()}>
        <Link href={`/app/manage/firms`} color="inherit">
          <Tooltip title="Firms" placement="right" arrow>
            <ListItem button>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Firms" />
            </ListItem>
          </Tooltip>
        </Link>
      </IfAnyGranted>
      <IfAnyGranted expected={atleastAdmin()} actual={getMyRoles()}>
        <Link href={`/app/manage/task_requests`} color="inherit">
          <Tooltip title="Task Requests" placement="right" arrow>
            <ListItem>
              <ListItemIcon>
                <GradingIcon />
              </ListItemIcon>
              <ListItemText primary="Task Requests" />
            </ListItem>
          </Tooltip>
        </Link>
      </IfAnyGranted>
      <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
        <Link href={`/app/manage/projects/new`} color="inherit">
          <Tooltip title="New Project" placement="right" arrow>
            <ListItem button>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Project" />
            </ListItem>
          </Tooltip>
        </Link>
      </IfAnyGranted>
      <IfAuthorized isAuthorized={hasReports()}>
        <Link href={`/app/reports`} color="inherit">
          <Tooltip title="Reports" placement="right" arrow>
            <ListItem button>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
          </Tooltip>
        </Link>
      </IfAuthorized>
      <IfAnyGranted expected={atleastSuperAngel()} actual={getMyRoles()}>
        <Link href={`/app/task_categories`} color="inherit">
          <Tooltip title="Task Categories" placement="right" arrow>
            <ListItem button>
              <ListItemIcon>
                <AccountTree />
              </ListItemIcon>
              <ListItemText primary="Task Categories" />
            </ListItem>
          </Tooltip>
        </Link>
      </IfAnyGranted>
      <Link href={`/app/knowledge_base`} color="inherit">
        <Tooltip title="Knowledge Base" placement="right" arrow>
          <ListItem>
            <ListItemIcon>
              <LightbulbIcon />
            </ListItemIcon>
            <ListItemText primary="Knowledge Base" />
          </ListItem>
        </Tooltip>
      </Link>
    </div>
  );
};
