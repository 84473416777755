import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Link } from "@mui/material/";
import Tooltip from '@mui/material/Tooltip';
import CategoryIcon from '@mui/icons-material/Category';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BusinessIcon from '@mui/icons-material/Business';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { removeAuthToken, stopImpersonation } from "../../../actions/_auth";


const onClickLogout = () => {
  stopImpersonation()
  removeAuthToken()
  window.location = '/'
}

export const mainListItems = (
  <div>
    <Link href={`/app/my_tasks`} color="inherit">
      <Tooltip title="My Tasks for Today" placement="right" arrow>
        <ListItem>
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="My Tasks for Today" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link href={`/app/all`} color="inherit">
      <Tooltip title="Dashboard" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Tooltip>
    </Link>
    {/* <ListItem button>
      <ListItemIcon>
        <AttachmentIcon />
      </ListItemIcon>
      <ListItemText primary="Files Drive" />
    </ListItem> */}
    {/* <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem> */}
    <Link href={`/app/manage/users`} color="inherit">
      <Tooltip title="Users" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Tooltip>
    </Link>
    {/* <Link href={`/app/manage/recurring_tasks`} color="inherit">
      <Tooltip title="Recurring Tasks" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <DynamicFeedIcon />
          </ListItemIcon>
          <ListItemText primary="Recurring Tasks" />
        </ListItem>
      </Tooltip>
    </Link> */}
    <Link href={`/app/manage/categories`} color="inherit">
      <Tooltip title="Categories" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link href={`/app/manage/firms`} color="inherit">
      <Tooltip title="Firms" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Firms" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link href={`/app/manage/projects/new`} color="inherit">
      <Tooltip title="New Project" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="New Project" />
        </ListItem>
      </Tooltip>
    </Link>
    <Link href={`/app/reports`} color="inherit">
      <Tooltip title="Reports" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
      </Tooltip>
    </Link>
  
    <Link href={`/app/task_categories`} color="inherit">
      <Tooltip title="Task Categories" placement="right" arrow>
        <ListItem button>
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Task Categories" />
        </ListItem>
      </Tooltip>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Organizations</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Rubix" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Chouxbox" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="DineTech" />
    </ListItem>
  </div>
);

export const lastListItems = (
  <div>
    <Tooltip title="Sign out" placement="right" arrow>
      <ListItem button onClick={onClickLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItem>
    </Tooltip>
  </div>
);