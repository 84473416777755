import { createSlice } from "@reduxjs/toolkit";

const previewNotificationSlice = createSlice({
  name: "preview_notification",
  initialState: { notification: {}, open: false, loading: false, data: {} },
  reducers: {
    setNotification(state, action) {
      state.notification = action.payload.notification;
      state.data = action.payload.data;
      state.loading = false;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const previewNotificationActions = previewNotificationSlice.actions;

export default previewNotificationSlice;