import React from 'react';
import { useSelector } from 'react-redux';
import MessageBubble from './_MessageBubble';

export default ({chat_room_id}) => {
  const chat_room = useSelector(state => state.chat_popup.chat_rooms[chat_room_id]);

  const isPrevChatFromUser = (message, index) => {
    return(index > 0 && message.user_id !== chat_room.messages[index - 1].user_id)
  }

  const popupID = () => {
    return `chat_popup_${chat_room_id}`
  }

  return (
    <div style={{height: '350px', overflowY: 'auto', paddingRight: '10px', paddingTop: '10px'}} id={popupID()}>
      {chat_room.messages.map((message, index) => {
        return(
          <MessageBubble
            key={`chat_m_${index}_${message.message_date}`}
            message={message}
            show_avatar={isPrevChatFromUser(message, index)}
          />
        )
      })}
    </div>
  )

}
