import React from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, lastListItems} from './listItems';
import OrgItems from './OrgItems';
import NavItems from './NavItems';

export default ({open, classes, handleDrawerClose}) => {
  return(
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List><NavItems /></List>
      <Divider />
      <List><OrgItems /></List>
      <Divider />
      <List>{lastListItems}</List>
    </Drawer>
  )
}