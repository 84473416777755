import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsLive from './_NotificationsLive';
import GeneralLive from './_GeneralLive';
import NotificationMenuItem from './_NotificationMenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { doAuthenticatedGet } from '../../../actions/_methods';
import { notificationsActions } from '../../../store/notifications_slice';
import {
  API_UNREAD_NOTIFICATIONS,
  API_MARK_AS_SEEN_NOTIFICATIONS
} from '../../../const/api_paths';

export default () => {
  // const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);

  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications.notifications);

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = () => {
    doAuthenticatedGet(API_UNREAD_NOTIFICATIONS, {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    console.log(res)
    // setNotifications(res.notifications);
    dispatch(notificationsActions.setNotifications({
      notifications: res.notifications
    }))
  }

  const onError = (res) => {
    console.log('--------ERROR-----------')
    console.log(res)
    console.log('-------------------')
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if(!isMenuOpen) {
      markAsSeen()
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickNotification = () => {
    setAnchorEl(null);
  }

  const markAsSeen = () => {
    doAuthenticatedGet(API_MARK_AS_SEEN_NOTIFICATIONS, {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    })
  }

  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      keepMounted
    >
      {notifications && notifications.map((n) => (
        <NotificationMenuItem
          key={`notification_${Math.random()}`}
          onClick={onClickNotification}
          notification={n}
        />
      ))}
    </Menu>
  );

  return(
    <>
      <IconButton
        color="inherit"
        onClick={handleProfileMenuOpen}
      >
        <Badge badgeContent={_.filter(notifications, ['seened', false]).length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {renderMenu}
      <NotificationsLive reloadNotification={loadNotifications} />
      <GeneralLive />
    </>
  )
};