import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: { notifications: [] },
  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload.notifications
    },
    addNotification(state, action) {
      state.notifications.push(action.payload.notification)
    }
  },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;