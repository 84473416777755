import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const chatPopupSlice = createSlice({
  name: "chat_popup",
  initialState: { myself: {}, users: [], channels: [], chat_rooms: {}, channel_status: {} },
  reducers: {
    setUsers(state, action) {
      state.users = action.payload.users
      state.myself = {
        id: action.payload.id,
        name: action.payload.display_name
      }
    },
    setChannels(state, action) {
      state.channels = action.payload.channels
    },
    setMessages(state, action) {
      state.chat_rooms[action.payload.chat_room_id]['messages'] = action.payload.messages
    },
    addMessage(state, action) {
      if(action.payload.message.user_id && state.myself.id && state.chat_rooms[action.payload.chat_room_id]) {
        action.payload.message.me_sender = (action.payload.message.user_id === state.myself.id)
        state.chat_rooms[action.payload.chat_room_id]['messages'].push(action.payload.message)
      }
    },
    updateMessage(state, action) {
      const chat_message = action.payload.chat_message;

      if(state.chat_rooms[action.payload.chat_room_id] !== undefined) {
        let chat_index = _.findIndex(state.chat_rooms[action.payload.chat_room_id].messages, {'id': chat_message.id})
        // state.chat_rooms[action.payload.chat_room_id].messages[chat_index] = {...chat_message}
        state.chat_rooms[action.payload.chat_room_id].messages[chat_index].received_by = chat_message.received_by
        state.chat_rooms[action.payload.chat_room_id].messages[chat_index].read_by = chat_message.read_by
      }
    },
    setChannelStatus(state, action) {
      state.channel_status[action.payload.channel_name] = action.payload.channel_status
    },
    openChatRoom(state, action) {
      let chat_room_id = action.payload.chat_room_id
      let id = action.payload.id
      let name = action.payload.name

      state.chat_rooms[chat_room_id] = {
        id: id,
        name: name,
        messages: []
      }
    },
    closeChatRoom(state, action) {
      let new_chat_rooms = {...state.chat_rooms};
      delete new_chat_rooms[action.payload.id];
      state.chat_rooms = new_chat_rooms;
    },
    updateChatChannel(state, action) {
      const data = action.payload;
      const chat_channel_id = action.payload.id;
      let chat_channel_index = _.findIndex(state.channels, {'id': chat_channel_id})
      state.channels[chat_channel_index].members = data.members
    },
  },
});

export const chatPopupActions = chatPopupSlice.actions;

export default chatPopupSlice;