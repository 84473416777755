export default {
  avatar_wrapper: {
    minWidth: '22px',
    marginRight: '10px'
  },
  avatar: {
    width: '22px',
    height: '22px'
  },
  chat_room_wrapper_others: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'flex-start',
  },
  chat_room_wrapper_me: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'flex-end',
  },
  chat_room_message_others: {
    flex: 1,
    borderRadius: '3px 5px 5px 5px',
    padding: '3px 7px',
    maxWidth: '200px',
    fontSize: '15px',
  },
  chat_room_message_me: {
    flex: 1,
    borderRadius: '5px 5px 3px 5px',
    padding: '3px 7px',
    maxWidth: '200px',
    fontSize: '15px',
  },
}