import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { SnackbarProvider } from 'notistack';
import { GlobalCtxProvider } from '../context/global';
import { KnowledgeBaseCtxProvider } from '../context/knowledge_base';

import { LAYOUT_STYLES } from './components/styles';

import PopupChats from '../chats/Popups';
import ScrollUp from '../../shared/components/utils/_scroll_up';
import KnowledgeBaseTips from '../../shared/components/KnowledgeBaseTips';

const useStyles = makeStyles((theme) => LAYOUT_STYLES(theme));

export const Layout = ({ children, AppBar, SideMenu }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <GlobalCtxProvider>
        <div className={classes.root}>
          <AppBar
            open={open}
            classes={classes}
            handleDrawerOpen={handleDrawerOpen}
          />
          <SideMenu
            open={open}
            classes={classes}
            handleDrawerClose={handleDrawerClose}
          />

          <KnowledgeBaseCtxProvider>
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth="xl" className={classes.container}>
                {children}
              </Container>
              <KnowledgeBaseTips />
              <ScrollUp />
              <PopupChats id={'chat_1'} />
            </main>
          </KnowledgeBaseCtxProvider>
        </div>
      </GlobalCtxProvider>
    </SnackbarProvider>
  );
};

export default Layout;
