import React, { useEffect } from 'react';
import { getAuthToken } from '../../../actions/_auth';
import { WS_HOST } from '../../../const/site';

const channel_name = 'GeneralChannel';

export default () => {
  let ws = null;
  let comp_name = `GL-${parseInt((Math.random() * 10000))}`

  useEffect(() => {
    console.log('useEffect', comp_name)
    connectWS()

    return () => {
      try {
        ws.terminate();
      }
      catch(err) { }
    }
  }, []);

  const connectWS = () => {
    try {
      ws.terminate();
    }
    catch(err) { }

    ws = new WebSocket(`${WS_HOST}cable?token=${getAuthToken()}`);
    ws.onopen    = onOpenConnection
    ws.onerror   = onErrorConnection
    ws.onclose   = onCloseConnection
    ws.onmessage = onMessageReceived
  };

  const subscribeToChannel = () => {
    try{
    ws.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel_name
        })
      })
    )
    } catch(err) {
      console.log('Error on subscribeToChannel')
      console.log(err)
    }
  }

  // const unsubscribeToChannel = () => {
  //   ws.send(
  //     JSON.stringify({
  //       command: 'unsubscribe',
  //       identifier: JSON.stringify({
  //         channel: channel_name
  //       })
  //     })
  //   )
  // }

  const onOpenConnection = (data) => {
    subscribeToChannel()
  }

  const onErrorConnection = (data) => {
    console.log('triggered onErrorConnection: ', comp_name)
    console.log(data)
    setTimeout(() => {
      console.log('onErrorConnection, GL. trying again in 30 seconds...', comp_name)
      // connectWS()
    }, 30000)
  }

  const onCloseConnection = (data) => {
    console.log('triggered onCloseConnection: ', comp_name)
    console.log(data)
    if(!data.wasClean) {
      setTimeout(() => {
        console.log('onCloseConnection, GL. trying again in 10 seconds...', comp_name)
        connectWS()
      }, 10000)
    }
  }

  const onMessageReceived = ({data}) => {
    const res = JSON.parse(data)

    if(res && res.message && res.message.action) {
      const elem = `${res.message.what}_${res.message.action}_${res.message.who}`;
      try {
        document.getElementById(elem).click()
      } catch(err) {
        console.log(err)
        // do nothing
      }
    }
  }

  return (
    <></>
  )
}
