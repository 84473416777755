import React, { createContext, useContext, useState } from "react";
import {
  ACCOUNTING_TASK_NEEDS_ACKNOWLEDGEMENT,
  REFERENCES_FIRM_DATA,
} from "../../const/api_paths";
import { doAuthenticatedGet } from "../../actions/_methods";

const FirmTasks = createContext();

const useFirmTasks = () => {
  const context = useContext(FirmTasks);

  if (!context) {
    throw new Error(`useFirmTasks must be used within a FirmTasksContext`);
  }
  return context;
};

const FirmTasksCtxProvider = ({ children }) => {
  const [tasks, setTasks] = useState([]);
  const [firmTasks, setFirmTasks] = useState([]);
  const [preferences, setPreferences] = useState({});
  const [keyName, setKeyName] = useState("due_date");
  const [sortAscending, setSortAscending] = useState(true);
  const [statusPriorityIndex, setStatusPriorityIndex] = useState(0);
  const [firmData, setFirmData] = useState({});

  const [loadingPending, setLoadingPending] = useState(false);
  const [showPendingItems, setShowPendingItems] = useState(false);
  const [pendingItemsTask, setPendingItemsTask] = useState(null);
  const [pendingAcknowledgements, setPendingAcknowledgements] = useState([]);

  const STATUSES = ["completed", "on_going", "paused", "stucked", "unstarted"];

  const sortByDate = (key) => {
    return firmTasks.sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return sortAscending ? dateB - dateA : dateA - dateB;
    });
  };

  const defaultSort = (key) => {
    return () => {
      return firmTasks.sort((a, b) => {
        const itemA = a[key];
        const itemB = b[key];
        return sortAscending ? itemB - itemA : itemA - itemB;
      });
    };
  };

  const sortByStatus = (key) => {
    setStatusPriorityIndex((prevIndex) => (prevIndex + 1) % STATUSES.length);
    const priorityStatus = STATUSES[statusPriorityIndex];

    return firmTasks.sort((a, b) => {
      if (a[key] === priorityStatus && b[key] !== priorityStatus) {
        return -1;
      } else if (a[key] !== priorityStatus && b[key] === priorityStatus) {
        return 1;
      }

      return a[key].localeCompare(b[key]);
    });
  };

  const filterList = {
    status: sortByStatus,
    due_date: sortByDate,
    work_hours: defaultSort,
    billable_hours: defaultSort,
    admin_est_hours: defaultSort,
    client_est_hours: defaultSort,
    last_count: defaultSort,
    processed_count: defaultSort,
    remaining_count: defaultSort,
  };

  const sortTasks = (key) => {
    const filterMethod = filterList[key];
    const filteredTasks = filterMethod(key);
    setFirmTasks(filteredTasks);
  };

  const resetSortParameters = () => {
    setKeyName("due_date");
    setStatusPriorityIndex(0);
    setSortAscending(true);
  };

  const fetchReferences = (task) => {
    setLoadingPending(true);

    doAuthenticatedGet(
      ACCOUNTING_TASK_NEEDS_ACKNOWLEDGEMENT,
      {
        task_id: task?.accounting_project_task_id,
      },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => alert("Please try again"),
      }
    );
  };

  const onSuccess = (res) => {
    setLoadingPending(false);
    setPendingAcknowledgements(res);
  };

  const loadFirmData = (firmId) => {
    doAuthenticatedGet(
      REFERENCES_FIRM_DATA,
      { firm_id: firmId },
      {
        success: (e) => {
          setFirmData(e);
        },
        general: (e) => {
          setFirmData(e);
        },
        error: () => {
          console.log("error", "loadFirmData");
        },
      }
    );
  };

  const value = {
    tasks,
    preferences,
    firmTasks,
    keyName,
    sortAscending,
    sortTasks,
    resetSortParameters,
    statusPriorityIndex,
    loadingPending,
    showPendingItems,
    pendingItemsTask,
    pendingAcknowledgements,
    firmData,
    setTasks,
    setPreferences,
    setFirmTasks,
    setKeyName,
    setSortAscending,
    fetchReferences,
    setLoadingPending,
    setShowPendingItems,
    setPendingItemsTask,
    setPendingAcknowledgements,
    loadFirmData,
  };

  return <FirmTasks.Provider value={value}>{children}</FirmTasks.Provider>;
};

export { FirmTasks, FirmTasksCtxProvider, useFirmTasks };
