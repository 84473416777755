import { createSlice } from "@reduxjs/toolkit";

const refSlice = createSlice({
  name: "ref",
  initialState: {
    myself: {},
    organizations: [],
    ref: { 
      users: [],
      categories: [],
      roles: [],
      stuck_options: [],
      departments: [],
    }},
  reducers: {
    setReferences(state, action) {
      state.myself = action.payload.myself;
      state.organizations = action.payload.organizations;
      state.ref = action.payload.references;
    },
  },
});

export const refActions = refSlice.actions;

export default refSlice;