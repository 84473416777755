import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';

import { chatPopupActions } from '../../store/chat_popup_slice';
import { useDispatch, useSelector } from 'react-redux';

export default ({user}) => {
  const dispatch = useDispatch();
  const myself = useSelector(state => state.chat_popup.myself)
  const chat_rooms = useSelector(state => state.chat_popup.chat_rooms)
  const channel_status = useSelector(state => state.chat_popup.channel_status)

  const handleOpenUserChat = () => {
    if(chat_rooms[getChatChannelName()]) {
      // do nothing?
    } else {
      // load data
      dispatch(chatPopupActions.openChatRoom({
        chat_room_id: getChatChannelName(),
        id: user.id,
        name: user.display_name
      }))
    }
  }

  const getChatChannelName = () => {
    let ids = new Float64Array([parseInt(myself.id), parseInt(user.id)])
    return `DM:${ids.sort().join(':')}`
  }

  const getLatestMessage = () => {
    if(channel_status && channel_status[getChatChannelName()]) {
      const message = channel_status[getChatChannelName()].latest_message;
      if(message.length > 45) {
        return `${message.substring(0, 42)}...`
      } else {
        return message
      }
    } else {
      return ''
    }
  }

  const isUnread = () => {
    if(channel_status && channel_status[getChatChannelName()]) {
      return channel_status[getChatChannelName()].is_unread;
    } else {
      return false
    }
  }

  return (

    <ListItemButton alignItems="flex-start" onClick={handleOpenUserChat} id={`chat_channel_link_${getChatChannelName()}`}>
      {isUnread() && <CircleIcon style={{color: 'green', marginTop: '20px'}} fontSize="small" />}
      <ListItemAvatar>
        <Avatar alt={user.full_name} src={user.profile_pic} />
      </ListItemAvatar>
      <ListItemText
        primary={user.full_name}
        secondary={<span dangerouslySetInnerHTML={{ __html: getLatestMessage() }} />}
      />
    </ListItemButton>
  );
}