import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import UserList from './UserList';
import ChatRoom from './ChatRoom';
import { STYLES } from './styles';
import { Button } from '@mui/material';

export default () => {
  const chat_rooms = useSelector(state => state.chat_popup.chat_rooms);
  const [connected, setConnected] = useState(false) 
  
  useEffect(() => {
    if (connected) {
      try {
        let permission = Notification.requestPermission();
      } catch (err) {
        // do nothing
      }
    }
  }, [connected])

  if (connected) {
    return (
      <div style={STYLES.popup_wrapper}>
        {Object.keys(chat_rooms).map(chat_room_id => {
          return (
            <div style={STYLES.popup_chat_room} key={`chat_popup_${chat_room_id}`}>
              <ChatRoom chat_room={chat_rooms[chat_room_id]} chat_room_id={chat_room_id} />
            </div>
          )
        })}
        <div style={STYLES.popup_user_list}>
          <UserList />
        </div>
      </div>
    )
  } else {
    return(
      <div style={STYLES.popup_wrapper}>
        <Button variant="contained" onClick={() => setConnected(true)}>Connect to Chat</Button>
      </div>
    )
  }
}
