import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getAuthToken } from '../../../actions/_auth';
import { WS_HOST } from '../../../const/site';

import { chatPopupActions } from '../../../store/chat_popup_slice';

const channel_name = 'ChatNotificationChannel';

export default () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [connected, setConnected] = useState(false);

  const channel_status = useSelector(state => state.chat_popup.channel_status)

  const dispatch = useDispatch();

  let ws = null;

  useEffect(() => {
    connectWS()
  }, []);

  const connectWS = () => {
    try {
      ws.terminate();
    }
    catch(err) { }

    ws = new WebSocket(`${WS_HOST}cable?token=${getAuthToken()}`);
    ws.onopen    = onOpenConnection
    ws.onerror   = onErrorConnection
    ws.onclose   = onCloseConnection
    ws.onmessage = onMessageReceived
  };

  const channelIdentifier = () => {
    return({
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: channel_name
      })
    })
  }

  const subscribeToChannel = () => {
    ws.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel_name
        })
      })
    )
  }

  const unsubscribeToChannel = () => {
    ws.send(
      JSON.stringify({
        command: 'unsubscribe',
        identifier: JSON.stringify({
          channel: channel_name
        })
      })
    )
  }

  const onOpenConnection = (data) => {
    subscribeToChannel()
  }

  const onErrorConnection = (data) => {
    setTimeout(() => {
      connectWS()
    }, 5000)
  }

  const onCloseConnection = (data) => {
    setTimeout(() => {
      connectWS()
    }, 5000)
  }

  const onMessageReceived = ({data}) => {
    const res = JSON.parse(data)

    if(res.type === "ping") {
      return false
    }

    if(res.type === "welcome") {
      return false
    }

    if(res.type === undefined) {
      dispatch(chatPopupActions.setChannelStatus({
        channel_name: res.message.message.channel_name,
        channel_status: res.message.message
      }))

      if(res.message.message.seconds_ago <= 3) {
        try {
          const local_notification = new Notification(`Frittata: ${res.message.message.latest_message}`)
          local_notification.addEventListener('click', () => {
            window.focus()
            document.getElementById(`chat_channel_link_${res.message.message.channel_name}`).click()
            local_notification.close();
          })
        } catch(err) {}
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        action={action}
      >
        <Alert severity="info" variant="filled">{message}</Alert>
        </Snackbar>
    </div>
  );
}
