import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ForumIcon from '@mui/icons-material/Forum';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

import User from './User';
import Channel from './Channel';
import AddChannel from './chat_list/_AddChannel';
import ChatNotificationLive from './chat_list/_ChatNotificationsLive';

import { chatPopupActions } from '../../store/chat_popup_slice';
import { useDispatch, useSelector } from 'react-redux';

import { API_CHAT_USERS } from '../../const/api_paths';
import { doAuthenticatedGet } from '../../actions/_methods';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default () => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const users = useSelector(state => state.chat_popup.users)
  const channels = useSelector(state => state.chat_popup.channels)
  const channel_status = useSelector(state => state.chat_popup.channel_status)

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (params) => {
    setLoading(true);
    doAuthenticatedGet(API_CHAT_USERS, {}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    dispatch(chatPopupActions.setUsers(res))
    dispatch(chatPopupActions.setChannels(res))
    setLoading(false);
  }

  const onError = (res) => {
    console.log('Chat Users not loaded....')
    setLoading(false);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
    <Card sx={{ width: 300 }} style={{position: 'absolute', bottom: 0, right: 0, border: '1px solid #cbcbcb', borderRadius: 0, boxShadow: '0px 0px 3px #cbcbcb'}}>
      <Collapse in={expanded} timeout="auto">
        <AddChannel />
        <CardContent style={{maxHeight: '400px', padding: '0', overflowY: 'auto'}}>
          {channels.map((channel) => <Channel channel={channel} key={`channel_list_${Math.random()}`} />)}
          {users.map((user) => <User user={user} key={`user_list_${Math.random()}`} />)}
        </CardContent>
      </Collapse>
      <CardActions disableSpacing>
        <IconButton aria-label="contacts">
        {_.filter(channel_status, ['is_unread', true]).length > 0 ? <ForumIcon  style={{color: 'green'}} /> : <ForumIcon />}
          <label style={{marginLeft: '20px', fontSize: '17px'}}>
            Chats
          </label>
        </IconButton>
        <ExpandMore
          expand={!expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
    </Card>
    <ChatNotificationLive />
    </>
  );
}