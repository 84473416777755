import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import { chatPopupActions } from '../../../store/chat_popup_slice';

import ManageMembers from './_ManageMembers';

export default ({chat_room, chat_room_id, onClose}) => {
  const [open, setOpen] = useState(false);
  const chat_users = useSelector(state => state.chat_popup.users);
  const channels = useSelector(state => state.chat_popup.channels);
  const dispatch = useDispatch();

  const chatName = () => {
    return chat_room.name
  }

  const chatChannel = () => {
    const channel = _.find(channels, ['id', chat_room.id]);
    return channel;
  }

  const chatInfo = () => {
    const channel = chatChannel();

    if(channel && channel.direct_message === false) {
      return(
        <div onClick={(e) => setOpen(true)}>
          {`${channel.members.length} members`}
        </div>
      )
    } else {
      return ''
    }
  }

  const chatterProfilePic = () => {
    const user = _.find(chat_users, ['id', chat_room.id])
    return(user ? user.profile_pic : null)
  }

  const avatarElem = () => {
    const profile_pic = chatterProfilePic()
    if(profile_pic) {
      return(
        <Avatar
          alt={chatName()}
          src={profile_pic}
          style={{ width: '40px', height: '40px' }}
        />
      )
    }
  }

  const actionButtons = () => (
    <IconButton aria-label="settings" onClick={onClickClose}>
      <HighlightOffIcon />
    </IconButton>
  )

  const onClickClose = (e) => {
    dispatch(
      chatPopupActions.closeChatRoom({id: chat_room_id})
    )
    onClose()
  }

  return (
    <>
      <CardHeader
        avatar={avatarElem()}
        action={actionButtons()}
        title={chatName()}
        subheader={chatInfo()}
        sx={{bgcolor: grey[100]}}
        style={{
          paddingTop: "7px",
          paddingBottom: "7px",
        }}
      />
      {open && <ManageMembers chat_channel={chatChannel()} onClose={() => setOpen(false)} />}
    </>
  )

}