import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';

const projectSlice = createSlice({
  name: "project",
  initialState: { 
    firm: {},
    accounts: [],
    categories: [],
    category_tasks: {},
    users: [],
  },
  reducers: {
    setFirmAndAccounts(state, action) {
      state.firm = action.payload.firm;
      state.accounts = action.payload.accounts;
    },
    appendAccount(state, action) {
      state.accounts = _.sortBy([...state.accounts, action.payload], ['name'])
    },
    removeAccount(state, action) {
      state.accounts = _.reject(state.accounts, {'id': action.payload})
    },
    appendLocation(state, action) {
      const account_id = action.payload.account_id;
      const location = action.payload.location;
      const new_accounts = [...state.accounts];

      const account = _.find(new_accounts, {'id': account_id});
      account.locations = _.sortBy([...account.locations, location], ['name']);
      state.accounts = new_accounts;
    },
    removeLocation(state, action) {
      const account_id = action.payload.account_id;
      const location = action.payload.location;
      const new_accounts = [...state.accounts];

      const account = _.find(new_accounts, {'id': account_id});
      account.locations = _.reject(account.locations, {'id': location.id})
      state.accounts = new_accounts;
    },
    setCategoriesEtc(state, action) {
      state.firm            = action.payload.firm;
      state.accounts        = action.payload.accounts;
      state.categories      = action.payload.categories; // to be removed
      state.category_tasks  = action.payload.category_tasks;
      state.users           = action.payload.users; // to be removed
    },
    addCategoryTask(state, action) {
      const category = action.payload.category;
      const task = action.payload.task;

      if(state.category_tasks[category.id] === undefined) {
        state.category_tasks[category.id] = {
          tasks: []
        }
      }

      state.category_tasks[category.id]['tasks'] = [...state.category_tasks[category.id]['tasks'], task]
    },
    setCategoryTaskLocations(state, action) {
      const task = action.payload.task;
      if(state.category_tasks[task.category_id] !== undefined) {
        let target_task = _.find(state.category_tasks[task.category_id].tasks, {'id': task.id})
        if(target_task) {
          target_task.locations = task.locations
        }
      }
    },
    updateCategoryTask(state, action) {
      const task = action.payload.task;
      if(state.category_tasks[task.category_id] !== undefined) {
        let task_index = _.findIndex(state.category_tasks[task.category_id].tasks, {'id': task.id})
        state.category_tasks[task.category_id].tasks[task_index] = {...task}
      }
    },
    deleteCategoryTask(state, action) {
      const task = action.payload.task;
      if(state.category_tasks[task.category_id] !== undefined) {
        let task_index = _.findIndex(state.category_tasks[task.category_id].tasks, {'id': task.id})
        state.category_tasks[task.category_id].tasks.splice(task_index, 1)

      }
    }
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice;