import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ManageMember from './_ManageMember';
import AddMember from './_AddMember';

export default ({chat_channel, onClose}) => {
  const handleEntering = () => {
    console.log('handleEntering....');
  };

  const handleCancel = (e) => {
    onClose()
  }

  const handleOk = (e) => {
    onClose()
  }

  const membersList = () => {
    return(
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {chat_channel.members.map(member => <ManageMember member={member} chat_channel={chat_channel} />)}
      </List>
    )
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={true}
    >
      <DialogTitle>#{`${chat_channel.name}`} (Members)</DialogTitle>
      <DialogContent dividers>
        <AddMember chat_channel={chat_channel} />
        <Divider />
        {membersList()}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );

}