import { configureStore } from "@reduxjs/toolkit";
import refSlice from "./ref_slice";
import chatPopupSlice from "./chat_popup_slice";
import projectSlice from "./project_slice";
import notificationsSlice from "./notifications_slice";
import previewNotificationSlice from "./preview_notification_slice";

const store = configureStore({
  reducer: {
    ref:                  refSlice.reducer,
    chat_popup:           chatPopupSlice.reducer,
    project:              projectSlice.reducer,
    preview_notification: previewNotificationSlice.reducer,
    notifications:        notificationsSlice.reducer,
  },
});
export default store;