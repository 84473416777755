import React, { useEffect, useState } from "react";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  Typography,
  Zoom,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { useGlobal } from "../../features/context/global";
import { useKnowledgeBase } from "../../features/context/knowledge_base";

const useStyles = makeStyles((_theme) => ({
  tooltipBody: {
    margin: "10px",
  },
  iconWrapper: {
    position: "absolute",
    bottom: "55px",
    right: "70px",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: "100",
  },
  documentItem: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  documentLink: {
    textDecoration: "none",
    cursor: "pointer",
  },
  documentIcon: {
    marginLeft: "10px",
    fontSize: "1rem",
    marginTop: "5px",
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#d3deda",
    color: "#3f3f3f",
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: "normal",
    border: "1px solid #d3deda",
    opacity: 1,
    borderRadius: "20px",
    marginRight: "20px",
  },
}));

const KnowledgeBaseTips = () => {
  const classes = useStyles();
  const { pageKnowledgeBaseTags } = useGlobal();
  const { pageDocuments, fetchKnowledgeBasesForPage } = useKnowledgeBase();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchKnowledgeBasesForPage(pageKnowledgeBaseTags);
  }, [pageKnowledgeBaseTags]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const toggleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  // Do not return anything if pageKnowledgeBaseTags is empty
  if (!pageKnowledgeBaseTags || pageKnowledgeBaseTags.length === 0) {
    return null;
  }

  // Do not return anything if pageDocuments is empty
  if (!pageDocuments || pageDocuments.length === 0) {
    return null;
  }

  // Check if any of the documents have files
  const hasFiles = pageDocuments.some((doc) => doc.files.length > 0);

  // Do not return anything if none of the documents have files
  if (!hasFiles) {
    return null;
  }

  const TooltipContent = (
    <Box className={classes.tooltipBody}>
      <Typography variant="h5">Need more help?</Typography>
      <Typography>
        Here are some useful documentation related to this page:
      </Typography>
      {pageDocuments.map((doc) => (
        <ul>
          <li key={doc.id}>
            <Typography variant="body" className={classes.documentItem}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={doc.files[0]?.url}
                className={classes.documentLink}
              >
                {doc.title}
                <OpenInNewIcon className={classes.documentIcon} />
              </Link>
            </Typography>
          </li>
        </ul>
      ))}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <HtmlTooltip
          title={TooltipContent}
          placement={"top"}
          TransitionComponent={Zoom}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton
            className={classes.iconWrapper}
            onClick={toggleTooltipOpen}
          >
            <LightbulbOutlinedIcon fontSize="large" />
          </IconButton>
        </HtmlTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default KnowledgeBaseTips;
