import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getAuthToken } from '../../actions/_auth';

const GlobalCtx = createContext();

const useGlobal = () => {
  const context = useContext(GlobalCtx);

  if (!context) {
    throw new Error(`useGlobal must be used within a GlobalContext`);
  }
  return context;
};

const GlobalCtxProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [myself, setMyself] = useState(null);
  const [firmUsers, setFirmUsers] = useState([]);
  const [pageKnowledgeBaseTags, setPageKnowledgeBaseTags] = useState([]);
  const localStorage = typeof window !== 'undefined' && window.localStorage;
  const selectedFirmKey = 'selectedFirm';

  const [isImpersonating, setIsImpersonating] = useState(() => {
    if (localStorage) {
      const saved = localStorage.getItem('isImpersonating');
      return saved === 'true';
    }
    return false;
  });

  const [impostor, setImpostor] = useState(() => {
    if (localStorage) {
      return localStorage.getItem('impostor');
    }
    return null;
  });

  const prompt = (message, options = {}) => {
    const snackbarOptions = {
      variant: options.variant || 'success',
      autoHideDuration: options.duration || 2500,
      disableWindowBlurListener: options.disableWindowBlurListener || true,
      ...options,
    };

    enqueueSnackbar(message, snackbarOptions);
  };

  const storeOriginalToken = () => {
    const KEY = 'billebotOriginalToken';
    const originalToken = getAuthToken();

    localStorage && localStorage.setItem(KEY, originalToken);
  };

  const storeSelectedFirm = (firm) => {
    const firmData = JSON.stringify(firm);
    localStorage && localStorage.setItem(selectedFirmKey, firmData);
  };

  const getSelectedFirm = () => {
    const firmData = localStorage && localStorage.getItem(selectedFirmKey);
    return firmData ? JSON.parse(firmData) : {tags: []};
  };

  useEffect(() => {
    if (isImpersonating) {
      if (localStorage.getItem('isImpersonating') === null) {
        localStorage.setItem('isImpersonating', 'true');
        localStorage.setItem('stopImpersonatePromptCount', 0);
      }

      if (impostor) {
        localStorage.setItem('impostor', impostor);
      }

      let count =
        parseInt(localStorage.getItem('impersonatePromptCount'), 10) || 0;

      if (impostor && count < 2) {
        prompt(`You are now logged in as ${impostor}`);
        localStorage.setItem('impersonatePromptCount', count + 1);
      }
    } else {
      let count = parseInt(
        localStorage.getItem('stopImpersonatePromptCount'),
        10
      );
      if (count < 2) {
        prompt(`You are no longer impersonating ${impostor}`);
        localStorage.setItem('stopImpersonatePromptCount', count + 1);
      }

      localStorage.removeItem('impersonatePromptCount');
      localStorage.removeItem('isImpersonating');
      if (count == 2) {
        localStorage.removeItem('stopImpersonatePromptCount');
        localStorage.removeItem('impostor');
      }
    }
  }, [isImpersonating, impostor]);

  const value = {
    prompt,
    users,
    myself,
    firmUsers,
    pageKnowledgeBaseTags,
    isImpersonating,
    setUsers,
    setMyself,
    setFirmUsers,
    setPageKnowledgeBaseTags,
    setIsImpersonating,
    setImpostor,
    storeOriginalToken,
    storeSelectedFirm,
    getSelectedFirm,
  };

  return <GlobalCtx.Provider value={value}>{children}</GlobalCtx.Provider>;
};

export { GlobalCtx, GlobalCtxProvider, useGlobal };
