import React, { useState } from "react";
import { Link, Menu, MenuItem, Avatar, Stack, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { signOut } from "../../../actions/_auth";

import { useReferences } from "../../context/references";

export default () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { myself } = useReferences();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSignOut = () => {
    setAnchorEl(null);
    signOut();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        onClick={handleClick}
      >
        <Avatar
          alt={myself.name}
          src={myself.profile_url}
          sx={{ width: 34, height: 34 }}
          style={{ display: "inline-block" }}
        />
        <h5>{myself.name}</h5>
        <Link variant="inherit" color="inherit" underline="none">
          <KeyboardArrowDownIcon />
        </Link>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            href={`/app/profile/change_password`}
            variant="body2"
            color="primary"
            underline="none"
          >
            Change Password
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            href={`/app/profile/change_profile_pic`}
            variant="body2"
            color="primary"
            underline="none"
          >
            Change Profile Pic
          </Link>
        </MenuItem>
        <MenuItem onClick={onSignOut}>
          <Link
            onClick={onSignOut}
            variant="body2"
            color="primary"
            underline="none"
          >
            Sign out
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};
