import React, { createContext, useContext, useEffect, useState } from 'react';
import _, { set } from 'lodash';
import { doAuthenticatedGet } from '../../actions/_methods';
import {
  REFERENCES_BASIC_ORGANIZATIONS,
  REFERENCES_MYSELF,
  REFERENCES_ORGANIZATIONS,
  REFERENCES_CATEGORIES,
  REFERENCES_TASK_CATEGORIES,
  REFERENCES_STUCK_OPTIONS,
  REFERENCES_CONNECTED_USERS,
  REFERENCES_FIRM_USERS,
} from '../../const/api_paths';

const ReferencesCtx = createContext();

const useReferences = () => {
  const context = useContext(ReferencesCtx);

  if (!context) {
    throw new Error(`useReferences must be used within a ReferencesContext`);
  }
  return context;
};

const ReferencesCtxProvider = ({ children }) => {
  const [myself, setMyself] = useState({});
  const [basic_organizations, setBasicOrganizations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [parent_categories, setParentCategories] = useState([]);
  const [sub_categories, setSubCategories] = useState([]);
  const [stuck_options, setStuckOptions] = useState([]);
  const [connected_users, setConnectedUsers] = useState([]);
  const [firm_id, setFirmId] = useState(null);
  const [firm_users, setFirmUsers] = useState([]);

  const [loaded_myself, setLoadedMyself] = useState(false);
  const [loaded_basic_organizations, setLoadedBasicOrganizations] = useState(false);
  const [loaded_organizations, setLoadedOrganizations] = useState(false);
  const [loaded_categories, setLoadedCategories] = useState(false);
  const [loaded_task_categories, setLoadedTaskCategories] = useState(false);
  const [loaded_stuck_options, setLoadedStuckOptions] = useState(false);
  const [loaded_connected_users, setLoadedConnectedUsers] = useState(false);
  const [loaded_firm_users, setLoadedFirmUsers] = useState(false);

  useEffect(() => {
    loadMyself();
  }, []);

  const loadMyself = () => {
    doAuthenticatedGet(
      REFERENCES_MYSELF,
      {},
      {
        success: (e) => { setMyself(e); setLoadedMyself(true) },
        general: (e) => { setMyself(e); setLoadedMyself(true) },
        error: () => { console.log('error', 'loadMyself') },
      }
    );
  }

  const loadBasicOrganizations = () => {
    doAuthenticatedGet(
      REFERENCES_BASIC_ORGANIZATIONS,
      {},
      {
        success: (e) => { setBasicOrganizations(e); setLoadedBasicOrganizations(true) },
        general: (e) => { setBasicOrganizations(e); setLoadedBasicOrganizations(true) },
        error: () => { console.log('error', 'loadBasicOrganizations') },
      }
    );
  }

  const loadOrganizations = () => {
    doAuthenticatedGet(
      REFERENCES_ORGANIZATIONS,
      {},
      {
        success: (e) => { setOrganizations(e); setLoadedOrganizations(true) },
        general: (e) => { setOrganizations(e); setLoadedOrganizations(true) },
        error: () => { console.log('error', 'loadOrganizations') },
      }
    );
  }

  const loadFirmOrganization = (firm_id) => {
    doAuthenticatedGet(
      REFERENCES_ORGANIZATIONS,
      {firm_id: firm_id},
      {
        success: (e) => { setOrganizations(e); setLoadedOrganizations(true) },
        general: (e) => { setOrganizations(e); setLoadedOrganizations(true) },
        error: () => { console.log('error', 'loadOrganizations') },
      }
    );
  }

  const loadCategories = () => {
    doAuthenticatedGet(
      REFERENCES_CATEGORIES,
      {},
      {
        success: (e) => { setCategories(e); setLoadedCategories(true)},
        general: (e) => { setCategories(e); setLoadedCategories(true) },
        error: () => { console.log('error', 'loadCategories') },
      }
    );
  }

  const loadTaskCategories = () => {
    doAuthenticatedGet(
      REFERENCES_TASK_CATEGORIES,
      {},
      {
        success: (e) => { setTaskCategories(e) },
        general: (e) => { setTaskCategories(e) },
        error: () => { console.log('error', 'loadTaskCategories') },
      }
    );
  }

  const setTaskCategories = (res) => {
    setParentCategories(res.parent_categories)
    setSubCategories(res.sub_categories)
    setLoadedTaskCategories(true)
  }

  const loadStuckOptions = () => {
    doAuthenticatedGet(
      REFERENCES_STUCK_OPTIONS,
      {},
      {
        success: (e) => { setStuckOptions(e); setLoadedStuckOptions(true)},
        general: (e) => { setStuckOptions(e); setLoadedStuckOptions(true) },
        error: () => { console.log('error', 'loadStuckOptions') },
      }
    );
  }

  const loadConnectedUsers = () => {
    doAuthenticatedGet(
      REFERENCES_CONNECTED_USERS,
      {},
      {
        success: (e) => { setConnectedUsers(e); setLoadedConnectedUsers(true)},
        general: (e) => { setConnectedUsers(e); setLoadedConnectedUsers(true) },
        error: () => { console.log('error', 'loadConnectedUsers') },
      }
    );
  }

  const loadFirmUsers = (firm_id) => {
    doAuthenticatedGet(
      REFERENCES_FIRM_USERS,
      {firm_id: firm_id},
      {
        success: (e) => { 
          setFirmId(firm_id);
          setFirmUsers(e);
          setLoadedFirmUsers(true);
        },
        general: (e) => {
          setFirmId(firm_id);
          setFirmUsers(e);
          setLoadedFirmUsers(true);
        },
        error: () => { console.log('error', 'loadMyself') },
      }
    );
  }

  const value = {
    myself,
    basic_organizations,
    organizations,
    categories,
    parent_categories,
    sub_categories,
    stuck_options,
    connected_users,
    firm_id,
    firm_users,
    loaded_myself,
    loaded_basic_organizations,
    loaded_organizations,
    loaded_categories,
    loaded_task_categories,
    loaded_stuck_options,
    loaded_connected_users,
    loaded_firm_users,
    loadMyself,
    loadBasicOrganizations,
    loadOrganizations,
    loadFirmOrganization,
    loadCategories,
    loadTaskCategories,
    loadStuckOptions,
    loadConnectedUsers,
    loadFirmUsers,
  };

  return (
    <ReferencesCtx.Provider value={value}>{children}</ReferencesCtx.Provider>
  );
};

export { ReferencesCtx, ReferencesCtxProvider, useReferences };
