import React, { createContext, useContext, useState } from "react";
import {
  API_KNOWLEDGE_BASES,
  API_KNOWLEDGE_BASES_FOR_PAGE,
} from "../../const/api_paths";
import { doAuthenticatedGet } from "../../actions/_methods";

const KnowledgeBaseCtx = createContext();

const useKnowledgeBase = () => {
  const context = useContext(KnowledgeBaseCtx);

  if (!context) {
    throw new Error(
      `useKnowledgeBase must be used within a KnowledgeBaseContext`
    );
  }
  return context;
};

const KnowledgeBaseCtxProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [pageDocuments, setPageDocuments] = useState([]);

  const fetchKnowledgeBases = () => {
    setLoading(true);
    doAuthenticatedGet(
      API_KNOWLEDGE_BASES,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccess = (results) => {
    setLoading(false);
    setDocuments(results.knowledge_bases);
  };

  const fetchKnowledgeBasesForPage = (tags) => {
    setLoading(true);
    doAuthenticatedGet(
      API_KNOWLEDGE_BASES_FOR_PAGE,
      { tags: tags },
      {
        success: onSuccessForPage,
        general: onSuccessForPage,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccessForPage = (results) => {
    setLoading(false);
    setPageDocuments(results.knowledge_bases);
  };

  const value = {
    loading,
    documents,
    pageDocuments,
    fetchKnowledgeBases,
    fetchKnowledgeBasesForPage,
  };

  return (
    <KnowledgeBaseCtx.Provider value={value}>
      {children}
    </KnowledgeBaseCtx.Provider>
  );
};

export { KnowledgeBaseCtx, KnowledgeBaseCtxProvider, useKnowledgeBase };
