export const STYLES = {
  wrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: '7px 7px 0 0',
    boxShadow: '0px 0px 3px #cbcbcb'
  },
  popup_wrapper: {
    position: 'absolute',
    bottom: '-2px',
    right: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: '100'
  },
  popup_chat_room: {
    position: 'relative',
    width: '300px',
    height: '1px',
    display: 'inline-block',
    marginRight: '12px',
    zIndex: 100
  },
  popup_user_list: {
    position: 'relative',
    width: '300px',
    height: '1px',
    display: 'inline-block'
  }
}