import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { doAuthenticatedPost } from '../../../actions/_methods';
import { API_CHAT_CHANNEL_USERS } from '../../../const/api_paths';
import { chatPopupActions } from '../../../store/chat_popup_slice';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

export default ({chat_channel}) => {
  const [value, setValue] = useState([]);
  const users = useSelector(state => state.chat_popup.users)
  const dispatch = useDispatch();

  const getUserIds = () => {
    return value.map((user) => {
      return user.id
    })
  };

  const onClickAddMember = (e) => {
    doAuthenticatedPost(API_CHAT_CHANNEL_USERS.replace(':chat_channel_id', chat_channel.id), {user_ids: getUserIds()}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    })
  };

  const onSuccess = (res) => {
    dispatch(chatPopupActions.updateChatChannel(res))
    setValue([])
  }

  const onError = (res) => {
    alert('Please try again')
  }

  return (
    <Box style={{marginBottom: '20px'}}>
      <div>
      <Autocomplete
        multiple
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        size="small"
        options={users}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Users" placeholder="Users" />
        )}
      />
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
        <Button
          variant="contained"
          onClick={onClickAddMember}
          size="small"
        >Add Member</Button>
      </div>
    </Box>
  )

}