import React from 'react';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';

import STYLES from './styles';

export default ({message}) => {

  return(
    <ListItemAvatar style={STYLES.avatar_wrapper}>
       <Tooltip title={message.sender_name} placement="top-start">
        <Avatar
          alt={message.sender_name}
          src={message.sender_image_url}
          style={STYLES.avatar}
        />
      </Tooltip>
    </ListItemAvatar>
  )

}