import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { grey } from '@mui/material/colors';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { doAuthenticatedPost } from '../../../actions/_methods';
import { API_CHAT_CREATE_CHANNEL } from '../../../const/api_paths';
import { chatPopupActions } from '../../../store/chat_popup_slice';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
// import _ from 'lodash';
// import { ConstructionOutlined } from '@mui/icons-material';

const STYLES = {
  modal: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -70%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }
};

export default () => {
  const [open, setOpen] = useState(false);
  const [channel_name, setChannelName] = useState('');
  const [value, setValue] = useState([]);
  const users = useSelector(state => state.chat_popup.users)

  const dispatch = useDispatch();

  const onClickAddChannel = (e) => {
    setOpen(true)
  };

  const onClickCloseAddChannel = (e) => {
    setOpen(false)
  };

  const getUserIds = () => {
    return value.map((user) => {
      return user.id
    })
  };

  const onClickCreateChannel = (e) => {
    if(channel_name.trim() !== '') {
      doAuthenticatedPost(API_CHAT_CREATE_CHANNEL, {channel_name: channel_name, user_ids: getUserIds()}, {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      })
    }
  };

  const onSuccess = (res) => {
    alert('Channel Created')
    setOpen(false);
    setChannelName('');
    dispatch(chatPopupActions.openChatRoom({
      chat_room_id: res.chat_channel.name,
      id: res.chat_channel.id,
      name: res.chat_channel.name
    }))
  }

  const onError = (res) => {
    alert(res.error)
  }

  const onClickCancel = (e) => {
    onClickCloseAddChannel(e)
  };

  const onChangeChannelName = (e) => {
    setChannelName(e.target.value)
  };

  const actionButtons = () => (
    <IconButton aria-label="settings" onClick={onClickAddChannel}>
      <AddCommentIcon />
    </IconButton>
  );

  return (
    <>
      <CardHeader
        action={actionButtons()}
        sx={{bgcolor: grey[100]}}
        style={{
          paddingTop: "7px",
          paddingBottom: "7px",
        }}
      />
      <Modal
        open={open}
        // onClose={onClickCloseAddChannel}
        aria-labelledby="Add New Chat Channel"
        aria-describedby="Add New Chat Channel"
      >
        <Box sx={STYLES.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Create a channel
          </Typography>
          <div style={{margin: "20px 0"}}>
            <TextField
              required
              id="new_channel_name"
              label="Channel Name"
              size="small"
              sx={{width: "100%"}}
              defaultValue=""
              value={channel_name}
              onChange={onChangeChannelName}
            />
          </div>
          <div>
          <Autocomplete
            multiple
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
            }}
            size="small"
            options={users}
            getOptionLabel={(option) => option.title}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.title}
                  {...getTagProps({ index })}
                />
              ))
            }
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Users" placeholder="Users" />
            )}
          />
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
            <Button
              variant="contained"
              onClick={onClickCreateChannel}
              style={{marginRight: '10px'}}
            >Create Channel</Button>
            <Button variant="text" onClick={onClickCancel}>Cancel</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}