import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import SenderAvatar from './_SenderAvatar';
import ReceivedBy from './_ReceivedBy';
import ReadBy from './_ReadBy';
import STYLES from './styles';
import { grey, blue } from '@mui/material/colors';
import moment from 'moment';
import _ from 'lodash';

export default ({message, show_avatar}) => {
  const [show_timestamp, setShowTimestamp] = useState(false)

  const bubbleBackground = () => {
    return({
      bgcolor: !isSender() ? grey[200]    : 'info.main',
      color:   !isSender() ? "#444444" : 'primary.contrastText',
    })
  }

  const wrapperStyles = () => {
    return( isSender() ? STYLES.chat_room_wrapper_me : STYLES.chat_room_wrapper_others )
  }

  const paperStyles = () => {
    return( isSender() ? STYLES.chat_room_message_me : STYLES.chat_room_message_others )
  }

  const isSender = () => {
    return message.me_sender 
  }

  const timestampElem = () => (
    <div>
      <small style={{fontSize: '11px', fontStyle: 'italic'}}>
        {moment(message.message_date).format("ddd, MMM D, h:mm:ss a")}
      </small>
    </div>
  )

  const receivedByElem = () => {
    if(message.received_by.length > 0) {
      return(
        <div>
          <small style={{fontSize: '11px', fontStyle: 'italic', display: 'flex'}}>
            Received By: 
            {message.received_by.map(e => <ReceivedBy user_id={e.user_id} timestamp={e.received_at} />)}
          </small>
        </div>
      )
    }
  }

  const readByElem = () => {
    if(message.received_by.length > 0) {
      return(
        <div>
          <small style={{fontSize: '11px', fontStyle: 'italic', display: 'flex'}}>
            Seen By: 
            {message.read_by.map(e => <ReadBy user_id={e.user_id} timestamp={e.received_at} />)}
          </small>
        </div>
      )
    }
  }
  
  const formatMessage = (message) => {
    if(message) {
      return message.replace(/(?:\r\n|\r|\n)/g, '<br>')
    } else {
      return message
    }
  }

  return(
    <div style={wrapperStyles()}>
      {show_avatar && !isSender() && <SenderAvatar message={message} />}

      <Paper
        elevation={0}
        rounded="5"
        style={paperStyles()}
        sx={bubbleBackground()}
        onDoubleClick={() => setShowTimestamp(!show_timestamp)}
        
      >
        {/* {message.body} */}
        <span dangerouslySetInnerHTML={{__html: formatMessage(message.body)}} />
        {show_timestamp && timestampElem()}
        {show_timestamp && receivedByElem()}
        {show_timestamp && readByElem()}
      </Paper>
    </div>
  )

}
