import React, { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { API_MY_ROLES } from '../../const/api_paths';
import { doAuthenticatedGet } from '../../actions/_methods';

const PermissionCtx = createContext();

const usePermission = () => {
  const context = useContext(PermissionCtx);

  if (!context) {
    throw new Error(`usePermission must be used within a PermissionContext`);
  }
  return context;
};

const PermissionCtxProvider = ({ children }) => {
  const [roles, setRoles] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    doAuthenticatedGet(
      API_MY_ROLES,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const onSuccess = (res) => {
    setRoles(res['my_roles']);
    setReports(res['my_reports']);
  };

  const onError = (res) => {
    console.log(res);
    alert('Permissions not loaded... Please try again...');
  };

  const getMyRoles = () => {
    return roles.map((i) => i.name);
  };

  const getRoleByFirm = (firm_id) => {
    if (isAngel()) {
      return 'angel';
    } else {
      const result = _.find(roles, { firm_id: firm_id });
      if (result === undefined) {
        return '';
      } else {
        return result.name;
      }
    }
  };

  const hasReports = () => {
    return reports.length > 0
  };

  const isSuperAngel = () => {
    return _.find(roles, { name: 'super_angel' }) !== undefined;
  };

  const isAtleastAngel = () => {
    return (isAngel() || isSuperAngel())
  };

  const isAngel = () => {
    return _.find(roles, { name: 'angel' }) !== undefined;
  };

  const isAdmin = () => {
    return _.find(roles, { name: 'admin' }) !== undefined;
  };

  const atleastUser = () => {
    return ['user', 'manager', 'admin', 'angel', 'super_angel'];
  };

  const atleastManager = () => {
    return ['manager', 'admin', 'angel', 'super_angel'];
  };

  const atleastAdmin = () => {
    return ['admin', 'angel', 'super_angel'];
  };

  const atleastAngel = () => {
    return ['angel', 'super_angel'];
  };

  const atleastSuperAngel = () => {
    return ['super_angel'];
  };

  const value = {
    roles,
    reports,
    getMyRoles,
    hasReports,
    getRoleByFirm,
    isAngel,
    isAdmin,
    isSuperAngel,
    isAtleastAngel,
    atleastSuperAngel,
    atleastUser,
    atleastManager,
    atleastAdmin,
    atleastAngel,
  };

  return (
    <PermissionCtx.Provider value={value}>{children}</PermissionCtx.Provider>
  );
};

export { PermissionCtx, PermissionCtxProvider, usePermission };
