import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';

import { chatPopupActions } from '../../store/chat_popup_slice';
import { useDispatch, useSelector } from 'react-redux';

export default ({channel}) => {
  const dispatch = useDispatch();
  const myself = useSelector(state => state.chat_popup.myself)
  const chat_rooms = useSelector(state => state.chat_popup.chat_rooms)
  const channel_status = useSelector(state => state.chat_popup.channel_status)

  const handleOpenUserChat = () => {
    if(chat_rooms[channel.name]) {
      // do nothing?
    } else {
      // load data
      dispatch(chatPopupActions.openChatRoom({
        chat_room_id: channel.name,
        id: channel.id,
        name: channel.name
      }))
    }
  }

  const getLatestMessage = () => {
    if(channel_status && channel_status[channel.name]) {
      return channel_status[channel.name].latest_message;
    } else {
      return ''
    }
  }

  const isUnread = () => {
    if(channel_status && channel_status[channel.name]) {
      return channel_status[channel.name].is_unread;
    } else {
      return false
    }
  }

  return (
    <ListItemButton alignItems="flex-start" onClick={handleOpenUserChat} id={`chat_channel_link_${channel.name}`}>
      {isUnread() && <CircleIcon style={{color: 'green', marginTop: '20px'}} fontSize="small" />}
      <ListItemText
        primary={`#${channel.name}`}
        secondary={getLatestMessage()}
      />
    </ListItemButton>
  );
}