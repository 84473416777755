import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';

import STYLES from './styles';

export default ({user_id, timestamp}) => {
  const users = useSelector(state => state.chat_popup.users);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(_.find(users, ['id', user_id]))
  }, []);

  if(user) {
  return(
    <ListItemAvatar style={STYLES.avatar_wrapper}>
       <Tooltip title={`${user.full_name} (${timestamp})`} placement="top-start">
        <Avatar
          alt={user.full_name}
          src={user.profile_pic}
          style={STYLES.avatar}
        />
      </Tooltip>
    </ListItemAvatar>
  )
  } else {
    return null
  }

}