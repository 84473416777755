import * as React from 'react';
import { useDispatch } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { doAuthenticatedDelete } from '../../../actions/_methods';
import { API_CHAT_CHANNEL_USERS } from '../../../const/api_paths';
import { chatPopupActions } from '../../../store/chat_popup_slice';

export default ({chat_channel, member}) => {
  const dispatch = useDispatch();

  const handleRemove = (e) => {
    if(window.confirm('Are you sure you want to remove from channel members?')) {

      doAuthenticatedDelete(API_CHAT_CHANNEL_USERS.replace(':chat_channel_id', chat_channel.id) + '/' + member.id, {}, {
        general: onSuccessRemove,
        success: onSuccessRemove,
        error: onErrorRemove
      });
    }
  }

  const onSuccessRemove = (res) => {
    dispatch(chatPopupActions.updateChatChannel(res))
    // afterDelete(res.account);
  }

  const onErrorRemove = (res) => {
    alert(`Please try again...`);
  }

  

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={member.title} src={member.profile_pic} />
        </ListItemAvatar>
        <ListItemText
          primary={member.title}
          secondary={
            <>
              <div>{member.email}</div>
              <Button variant="contained" color="error" size="small" style={{marginTop: '10px'}} onClick={handleRemove}>
                Remove Here
              </Button>
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}