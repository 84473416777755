import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SendIcon from '@mui/icons-material/Send';
import { MentionsInput, Mention } from 'react-mentions'

import MentionStyles from './mentionStyles';

import { useSelector } from 'react-redux';

export default ({onSend}) => {
  const [message, setMessage] = useState('');
  const users = useSelector(state => state.chat_popup.users)

  const onChangeTextField = (e) => {
    setMessage(e.target.value)
  }

  const onKeyPressTextField = (e) => {
    if(isEnter(e)) {
      e.preventDefault()
      doOnSend()
      return false
    }
  }
  
  const onDisplayTransform = (id, display) => {
    return "@" + display
  }

  const doOnSend = () => {
    onSend(message)
    setMessage('')
  }

  const isEnter = (e) => {
    let key_code = (e.which || e.keyCode)
    return(key_code === 13 && !e.shiftKey)
  }

  return (
    <div style={{display: 'flex'}}>
      <MentionsInput 
        maxRows={4}
        minRows={2}
        variant="filled"
        style={MentionStyles}
        value={message}
        onChange={onChangeTextField}
        onKeyPress={onKeyPressTextField}
        allowSuggestionsAboveCursor={true}
      >
        <Mention
          trigger="@"
          data={users}
          markup='<a class="chat_mention">@__display__</a>'
          displayTransform={onDisplayTransform}
          appendSpaceOnAdd={true}
          style={MentionStyles}
        />
      </MentionsInput>
      <IconButton
        color="primary"
        aria-label="Send Message"
        component="span"
        edge="end"
        onClick={() => doOnSend()}
      >
        <SendIcon />
      </IconButton>
    </div>
  )

}