import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Link, Avatar } from "@mui/material/";
import Tooltip from '@mui/material/Tooltip';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useGlobal } from '../../context/global';
import { useReferences } from '../../context/references';

export default () => {
  const { basic_organizations, loadBasicOrganizations } = useReferences();

  useEffect(() => {
    loadBasicOrganizations()
  }, []);

  return(
    <div style={{height: '300px', overflowX: 'hidden', overflowY: 'auto'}}>
      <ListSubheader inset>Organizations</ListSubheader>
      <Tooltip title="All Tasks" placement="right" arrow>
        <Link href={`/app/all`} color="inherit">
          <ListItem button>
            <ListItemIcon>
              <FormatListNumberedIcon />
            </ListItemIcon>
            <ListItemText primary="All Tasks" />
          </ListItem>
        </Link>
      </Tooltip>
      {basic_organizations.map((firm) => {
        return(
          <Tooltip title={firm.name} key={`quick_sidelink_${firm.id}`} placement="right" arrow>
            <Link href={`/app/firm/${firm.id}`} color="inherit" key={`menu_firm_${firm.id}`}>
              <ListItem button>
                <ListItemIcon>
                  <Avatar key={`org_avatar_${Math.random()}`} alt={firm.name} src={firm.avatar}>
                    {firm.code}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={firm.name} />
              </ListItem>
            </Link>
          </Tooltip>
        )
      })}
    </div>
  )

};
