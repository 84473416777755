import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';

import { doAuthenticatedGet } from '../../actions/_methods';
import { getAuthToken } from '../../actions/_auth';
import { WS_HOST } from '../../const/site';
import { API_CHAT_MESSAGES } from '../../const/api_paths';
import { chatPopupActions } from '../../store/chat_popup_slice';

import ChatBox from './chat_room/_ChatBox';
import Messages from './chat_room/_Messages';
import Header from './chat_room/_Header';
import { STYLES } from './styles';

const channel_name = 'ChatChannelChannel';

export default ({chat_room, chat_room_id}) => {
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [other_ws, setOtherWs] = useState(null);
  const [page_visibility, setPageVisibility] = useState('');
  const [page_focus, setPageFocus] = useState(true);
  const [close_it, setCloseIt] = useState(false);
  const dispatch = useDispatch();
  const chat_room_state = useSelector(state => state.chat_popup.chat_rooms[chat_room_id]);
  let ws = null;

  useEffect(() => {
    connectWS();
    loadInitialChats();
    setPageVisibility(document.visibilityState);
    monitorPageVisibility();
  }, []);

  useEffect(() => {
    console.log(page_visibility)
    if(page_visibility === 'visible' && page_focus) {
      updateAsRead()
    }
  }, [page_visibility, page_focus]);

  const monitorPageVisibility = () => {
    document.addEventListener('visibilitychange', (event) => {
      setPageVisibility(document.visibilityState)
    })

    document.addEventListener('focusin', (event) => {
      setPageFocus(true)
    })

    document.addEventListener('focusout', (event) => {
      setPageFocus(false)
    })
  }

  const updateAsRead = () => {
    const last_message = _.last(chat_room_state.messages);
    if(last_message && last_message.id) {
      notifyRead(last_message.id);
    }
  }

  const connectWS = () => {
    try { ws.terminate() }
    catch(err) { }

    ws = new WebSocket(`${WS_HOST}cable?token=${getAuthToken()}`);
    ws.onopen    = onOpenConnection
    ws.onerror   = onErrorConnection
    ws.onclose   = onCloseConnection
    ws.onmessage = onMessageReceived
    setOtherWs(ws)
  }

  const reconnectWS = () => {
    setTimeout(() => {
      connectWS()
    }, 5000)
  }

  const loadInitialChats = () => {
    doAuthenticatedGet(API_CHAT_MESSAGES, {chat_room_id: chat_room_id}, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    dispatch(chatPopupActions.setMessages({chat_room_id: chat_room_id, messages: res.messages}))
    scrollToBottom()
    updateAsRead()
  }

  const onError = (res) => {}

  const onSend = (new_message) => {
    var msg = {
      command: 'message',
      identifier: JSON.stringify({
        chat_room_id: chat_room_id,
        channel: channel_name
      }),
      data: JSON.stringify({
        message: new_message
      })
    }

    if(other_ws) {
      try { other_ws.send(JSON.stringify(msg)) } catch(err) {}
    } else {
      try { ws.send(JSON.stringify(msg)) } catch(err) {}
    }
  }

  const notifyReceived = (message) => {
    var msg = {
      command: 'message',
      identifier: JSON.stringify({
        chat_room_id: chat_room_id,
        channel: channel_name
      }),
      data: JSON.stringify({
        message: message,
        type: 'notify'
      })
    }

    if(other_ws) {
      try { other_ws.send(JSON.stringify(msg)) } catch(err) {}
    } else {
      try { ws.send(JSON.stringify(msg)) } catch(err) {}
    }
  }

  const notifyRead = (message) => {
    var msg = {
      command: 'message',
      identifier: JSON.stringify({
        chat_room_id: chat_room_id,
        channel: channel_name
      }),
      data: JSON.stringify({
        message: message,
        type: 'notify_read'
      })
    }

    if(other_ws) {
      try { other_ws.send(JSON.stringify(msg)) } catch(err) {}
    } else {
      try { ws.send(JSON.stringify(msg)) } catch(err) {}
    }
  }

  const subscribeToChannel = () => {
    var msg = {
      command: 'subscribe',
      identifier: JSON.stringify({
        chat_room_id: chat_room_id,
        channel: channel_name
      }),
    }

    ws.send(JSON.stringify(msg))
  }

  // const unsubscribeToChannel = () => {
  //   var msg = {
  //     command: 'unsubscribe',
  //     identifier: JSON.stringify({
  //       chat_room_id: chat_room_id,
  //       channel: channel_name
  //     }),
  //   }
  //   ws.send(JSON.stringify(msg))
  // }

  const onOpenConnection = (data) => {
    setConnected(true);
    subscribeToChannel()
  }

  const onErrorConnection = (data) => {
    reconnectWS()
  }

  const onCloseConnection = (data) => {

    if(data.reason === 'intended') {
      // do nothing
    } else {
      reconnectWS()
    }
  }

  const onMessageReceived = ({data}) => {
    const res = JSON.parse(data)

    if(res.type === 'welcome') {
      if(!subscribed) { setSubscribed(true) }
      return false
    }
    
    if(res.type === 'ping') {
      return false
    }

    if(res.message && res.message.type == "received_notify") {
      dispatch(chatPopupActions.updateMessage({chat_room_id: chat_room_id, chat_message: res.message}))
      return false
    }

    if(res.message) {
      dispatch(chatPopupActions.addMessage({chat_room_id: chat_room_id, message: res.message}))
      scrollToBottom()
      setTimeout(() => notifyReceived(res.message.id), 1000)
      setTimeout(() => {
        if(page_visibility === 'visible') {
          notifyRead(res.message.id)
        }
      }, 1000)
    }
  }

  const scrollToBottom = () => {
    var objDiv = document.getElementById(popupID());
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const onClose = () => {
    setCloseIt(true)
    setTimeout(() => {
      if(other_ws) {
        try { other_ws.close(1000, 'intended') } catch(err) {}
      } else {
        try { ws.close(1000, 'intended') } catch(err) {}
      }
    }, 1000)
  }

  const popupID = () => {
    return `chat_popup_${chat_room_id}`
  }

  return (
    <Card sx={{ width: 300, border: connected ? '1px solid #8fb5e2' : '1px solid #cbcbcb' }} style={STYLES.wrapper}>
      <Header chat_room={chat_room} chat_room_id={chat_room_id} onClose={onClose} />
      <CardContent sx={{paddingTop: 0, paddingBottom: 0, paddingRight: 0}}>
        <Messages chat_room_id={chat_room_id} />
      </CardContent>
      <CardContent sx={{bgcolor: grey[100]}}>
        {connected && <ChatBox onSend={onSend} />}
      </CardContent>
    </Card>
  )

}