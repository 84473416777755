import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { getAuthToken } from '../../../actions/_auth';
import { WS_HOST } from '../../../const/site';

const channel_name = 'NotificationChannel';

export default ({reloadNotification}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  let ws = null;
  let comp_name = `NL-${parseInt((Math.random() * 10000))}`

  useEffect(() => {
    console.log('useEffect', comp_name)
    connectWS()

    return () => {
      try {
        ws.terminate();
      }
      catch(err) { }
    }
  }, []);

  const connectWS = () => {
    console.log('connectWS', comp_name)
    try {
      ws.terminate();
    }
    catch(err) { }

    ws = new WebSocket(`${WS_HOST}cable?token=${getAuthToken()}`);
    ws.onopen    = onOpenConnection
    ws.onerror   = onErrorConnection
    ws.onclose   = onCloseConnection
    ws.onmessage = onMessageReceived
  };

  const channelIdentifier = () => {
    return({
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: channel_name
      })
    })
  }

  const subscribeToChannel = () => {
    try {
    ws.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel_name
        })
      })
    )
    } catch(err) { 
      console.log('Error on subscribeToChannel')
      console.log(err)
    }
  }

  const unsubscribeToChannel = () => {
    ws.send(
      JSON.stringify({
        command: 'unsubscribe',
        identifier: JSON.stringify({
          channel: channel_name
        })
      })
    )
  }

  const onOpenConnection = (data) => {
    subscribeToChannel()
  }

  const onErrorConnection = (data) => {
    console.log('triggered onErrorConnection: ', comp_name)
    console.log(data)
    setTimeout(() => {
      console.log('onErrorConnection, trying again in 30 seconds...', comp_name)
      // connectWS()
    }, 30000)
  }

  const onCloseConnection = (data) => {
    console.log('triggered onCloseConnection: ', comp_name)
    console.log(data)
    if(!data.wasClean) {
      setTimeout(() => {
        console.log('onCloseConnection, trying again in 10 seconds...', comp_name)
        connectWS()
      }, 10000)
    }
  }

  const onMessageReceived = ({data}) => {
    const res = JSON.parse(data)

    if(res && res.message && res.message.message) {
      setMessage(res.message.message);
      setOpen(true)

      reloadNotification()
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        action={action}
      >
        <Alert severity="info" variant="filled">{message}</Alert>
        </Snackbar>
    </div>
  );
}
